* {
  box-sizing: border-box;
}

body {
  background: rgb(84, 54, 82);
  background: radial-gradient(circle at 0 100%, rgba(84, 54, 82, 1) 0%, rgba(29, 29, 29, 1) 57%) fixed;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  line-height: 0;
}

img {
  max-width: 100%;
  max-height: 100%;
}

a {
  display: inline-block;
  text-decoration: none;
}

.grain {
  background-image: url(../img/grain.gif);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.2;
  pointer-events: none;
  filter: invert();
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding: 50px 0 25px;
}
.content .logo {
  width: 165px;
}
.content .albums {
  max-width: 80%;
  display: flex;
  flex-direction: column;
}
.content .links {
  display: flex;
}
.content .links a {
  transform: scale(0.6);
}

.content .links img {
  height: 33px;
}

@media screen and (min-width: 1000px) {
  .content {
    height: 100vh;
    gap: 0;
    padding: 0;
  }

  .content .logo {
    height: 20%;
    width: 230px;
    align-content: center;
  }

  .content .albums {
    height: 69%;
    flex-direction: row;
    justify-content: center;
  }

  .links {
    height: 11%;
    padding-right: 70px;
    padding-bottom: 40px;
    align-items: flex-end;
    align-self: flex-end;
  }
}
